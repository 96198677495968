:root {
    --darkest-blue: #084F8C;
    --blue: #085A8C;
    --lighter-blue: #0B688C;
    --teal: #14808C;
    --green:#1B8C77;
    --white: #ffffff;
}

* {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.body {
    background-color: var(--darkest-blue);
}

a {
    color: var(--white);
}


.headerphoto {
    height: 400px;
    object-fit: cover;
    background-image: url("../src/assets/images/websiteheader.png");
    background-position: center;
    margin: auto;
}
.navbar{
    color: var(--lighter-blue);
    background-color: white;
    font-size: 30px;
}
.nav-logo {
    float: center !important;
    justify-content: space-around;
    padding-left: 25px;
    transform: scale(1.8)
  }
main {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 20px;

}

.about-me {
    font-size: 25px;
    text-align: left;
    padding: 10px;
    background-color:var(--darkest-blue);
    color: white;
}


.signup-form-section {
    padding-bottom: 5rem;
    width: calc(100% - 1rem);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-form-section h3 {
    font-size: 1.5rem;
}
.footer {
    padding: 40px;
    clear: both;
    text-align: left;
    background-color: var(--darkest-blue);
    color: var(--white);
}

.footer h2 {
    font-size: 16px;
}

@media screen and (min-width: 768px) {
    .work {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .work:last-child {
      flex-grow: 1;
    }
  }
@media screen and (min-width: 992px) {
    header {
      width: 100%;
    }
  
    .work {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
}